.toggle{
	position: relative;
	width: 50px;
	height: 30px;
	/* top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
}

.base
{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 50px;
	height: 30px;
	-webkit-appearance: none;
	background: var(--active);
	outline: none;
	border-radius: 40px;
	box-shadow: inset 0 0 5px rgba(0,0,0, 0.2);
	transition: 0.3s;
}


.status
{
	content: '';
	position: absolute;
	width: 24px;
	height: 24px;
	top: 3px;
	left: var(--position);
	border-radius: 20px;
	background: #fff;
	transform: scale(1.1);
	box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
	transition: 0.3s;
}
