/* * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
} */

/* *::after, *::before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
} */

/*************************** Основные пункты **/

#mainmenu {
	position: relative;
	/* border: 1px solid #222;
	background-color: #111;
	background-image: -moz-linear-gradient(#444, #111);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111));
	background-image: -webkit-linear-gradient(#444, #111);
	background-image: -o-linear-gradient(#444, #111);
	background-image: linear-gradient(#444, #111); */
	border-radius: 6px;
	-moz-border-radius: 6px;
	-o-border-radius: 6px;
	-webkit-border-radius: 6px;
	-ms-border-radius: 6px;
	/* box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
	-moz-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
	-o-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
	-ms-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
	-webkit-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset; */
	height: 40px;
	/* margin: 50px auto; */
	padding: 0;
	/* width: 960px; */
	z-index: 10/* для отображения подпунктов поверх остальных блоков */
}

#mainmenu ul,
/* сбрасываем поля и отступы у списков */

#mainmenu ul ul, #mainmenu ul ul li {
	list-style: none;
	padding: 0;
	margin: 0;
}

#mainmenu ul {
	/* clear: left;
	position: relative;
	right: 50%;
	height: 40px;
	float: right;
	text-align: center;
	font: 12px Arial, Helvetica, sans-serif;
	text-transform: uppercase; */
}

#mainmenu ul li {
	/* border-right: 1px solid #222;
	box-shadow: 1px 0 0 #444;
	-moz-box-shadow: 1px 0 0 #444;
	-o-box-shadow: 1px 0 0 #444;
	-ms-box-shadow: 1px 0 0 #444;
	-webkit-box-shadow: 1px 0 0 #444; */
	position: relative;
	/* left: 50%; */
	float: left;
	height: 39px;

}

#mainmenu ul li:last-child {
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none
}

#mainmenu ul li a {
	display: flex;
	color: #546e7a;
	/* var(--color-primary); */
	/* font-size: 14px; */
	text-decoration: none;
	padding: 13px 25px
}

#mainmenu ul>li:hover>a {
	background-color: #e8eaf6;
	/* background-image: -moz-linear-gradient(#e8eaf6, #e8eaf6); */
	/* background-image: -webkit-gradient(linear, left top, left bottom, from(#04acec), to(#0186ba)); */
	/* background-image: -webkit-linear-gradient(#04acec, #0186ba); */
	background-image: -o-linear-gradient(#e8eaf6, #c5cae9);
	background-image: linear-gradient(#e8eaf6, #c5cae9);
	border-left: 3px solid var(--color-primary);
	/* box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); */

	color: var(--color-primary)
}

/** Индикатор наличия подкатегорий верхнего уровня **/

#mainmenu>ul>li.parent>a::after {
	border-left: 1px solid var(--color-primary);
	border-top: 1px solid var(--color-primary);
	content: "";
	display: inline-block;
	vertical-align: top;
	margin: 4px 0 0 8px;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
	height: 5px;
	width: 5px;
}

/*
%MINIFYHTML8f898a51dc40a818749d1f383e1cb06e6%%MINIFYHTML8f898a51dc40a818749d1f383e1cb06e7%
/*************************** Выпадающие подпункты

%MINIFYHTML8f898a51dc40a818749d1f383e1cb06e8%%MINIFYHTML8f898a51dc40a818749d1f383e1cb06e9% */

#mainmenu ul li ul {
	/* background: red; */
	/* background: -moz-linear-gradient(#444, #111); */
	/* background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111)); */
	/* background: -webkit-linear-gradient(#444, #111); */
	/* background: -o-linear-gradient(#444, #111); */
	/* background: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111)); */
	background: linear-gradient(#bdbdbd, #fafafa);
	box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

	border-radius: 3px;
	-moz-border-radius: 3px;
	-o-border-radius: 3px;
	-ms-border-radius: 3px;
	-webkit-border-radius: 3px;
	transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	text-transform: none;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 0;
	line-height: 18px;
	top: 39px;
	font-size: 12px;
	margin-top: 20px;
	height: auto;
	min-width: 150px;
	width: 100%
}

#mainmenu ul li:hover>ul {
	opacity: 1;
	visibility: visible;
	-webkit-transition: margin 0.3s ease 0s;
	-moz-transition: margin 0.3s ease 0s;
	-o-transition: margin 0.3s ease 0s;
	transition: margin 0.3s ease 0s;
	margin: 0;
	z-index: 11;
}

#mainmenu ul li ul li {
	border: none;
	/* margin: ; */
	/* box-shadow: 0 1px 0 #111, 0 2px 0 #666; */
	/* -moz-box-shadow: 0 1px 0 #111, 0 2px 0 #666; */
	/* -o-box-shadow: 0 1px 0 #111, 0 2px 0 #666; */
	/* -webkit-box-shadow: linear-gradient(#fafafa, #bdbdbd); */

	-webkit-box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fafafa;
	/* -ms-box-shadow: 0 1px 0 #111, 0 2px 0 #666; */
	position: relative;
	left: 0;
	float: none;
	height: auto;
	text-align: left !important;
}

#mainmenu ul li ul li:last-child {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

#mainmenu ul li ul li a {
	padding: 10px 15px;
	border: none
}

/*************************** Выпадающие пункты второго уровня **/

#mainmenu ul li ul li ul {
	top: 0%;
	left: 100%;
	margin: 0 0 0 20px;
	_margin: 0;
	/*IE6 only*/
	box-shadow: -1px 0 0 rgba(255, 255, 255, .3);
	-moz-box-shadow: -1px 0 0 rgba(255, 255, 255, .3);
	-o-box-shadow: -1px 0 0 rgba(255, 255, 255, .3);
	-ms-box-shadow: -1px 0 0 rgba(255, 255, 255, .3);
	-webkit-box-shadow: -1px 0 0 rgba(255, 255, 255, .3);
}

/** Индикатор наличия подкатегорий 2 уровня **/

#mainmenu>ul>li ul li.parent>a::after {
	border-left: 1px solid var(--color-primary);
	border-top: 1px solid var(--color-primary);
	content: "";
	display: inline-block;
	vertical-align: top;
	margin: 7px 0px 0px 8px;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
	height: 5px;
	width: 5px;
	float: right;
}

/********************** Стрелочки на подпунктах **/
/*
#mainmenu ul ul li:first-child>a {
	border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	-o-border-radius: 3px 3px 0 0;
	-ms-border-radius: 3px 3px 0 0;
	-webkit-border-radius: 3px 3px 0 0;
}

#mainmenu ul li>ul>li:first-child>a:before {
	content: 111»;
	position: absolute;
	left: 40px;
	top: -6px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #444;
}

#mainmenu ul ul ul li:first-child a:before {
	left: -6px;
	top: 50%;
	margin-top: -6px;
	border-left: 0;
	border-bottom: 6px solid transparent;
	border-top: 6px solid transparent;
	border-right: 6px solid #3b3b3b;
}

#mainmenu ul ul li:first-child a:hover:before {
	border-bottom-color: #04acec
}

#mainmenu ul ul ul li:first-child a:hover:before {
	border-right-color: #0299d3;
	border-bottom-color: transparent;
}

#mainmenu ul ul li:last-child>a {
	-moz-border-radius: 0 0 3px 3px;
	-webkit-border-radius: 0 0 3px 3px;
	border-radius: 0 0 3px 3px;
}

#mainmenu>ul>li.parent>ul>li:first-child>a::before {
	display: none;
}

#mainmenu>ul>li.parent>a::before {
	bottom: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid red;
	content: »111;
	visibility: hidden;
	position: absolute;
	left: 50%;
	margin-left: -6px;
}

#mainmenu>ul>li.parent:hover>a::before {
	visibility: visible;
	-webkit-transition: all .5s ease .2s;
	-moz-transition: all .5s ease .2s;
	-o-transition: all .5s ease .2s;
	transition: all .5s ease .2s;
} */
