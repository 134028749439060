body {
  background-color: white;
  font-family: sans-serif;
}

table.calendar {
  border-collapse: collapse;
}

table.calendar thead {
  background-color: #5A5A5A;
  color: white;
  margin-bottom: 3px;
  border-bottom: 2px solid white
}


table.calendar thead th {
  font-weight: normal;
  padding: 10px 3px;
}

table.calendar thead th.bolder {
  font-weight: bold;
}

table.calendar tbody {
  font-size: 0.8em;
}

table.calendar td {
  text-align: center;
  padding: 6px;
  cursor: pointer;
  border: 1px solid rgba(185, 185, 185, 0.13);
  background-color: white;
  min-width: 15px;
}

table.calendar tr:last-child td {
  border-bottom: none;
}

table.calendar td.month-name {
  font-weight: bold;
  text-align: left;
  cursor: default;
  border-left: none;
}

table.calendar td.prev-month,
table.calendar td.next-month {
  color: transparent;
  cursor: default;
  pointer-events: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.week-separator {
  pointer-events: none;
  padding: 0;
  width: 8px;
  min-width: 0;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=');
}

table.calendar td.bolder {
  font-weight: bold;
}

/* Single selected day */
table.calendar td.selected {
  background-color:#9e9e9e;
  color: black;
  font-weight: bold;
}

/* Selected range */
table.calendar td.range {
  background-color: #9e9e9e;
  font-weight: bold;
  color: black;
}

table.calendar td.range-left {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
  background: #9e9e9e;
}

table.calendar td.range-right {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  background: #9e9e9e;
}

div.calendar-controls {
  margin: 5px auto;
  display: table;
  font-size: 25px;
  line-height: 35px;
}

div.calendar-controls div {
  display: inline;
}

div.calendar-controls .current-year {
  margin: 0 30px;
}

div.calendar-controls .control {
  font-weight: bolder;
  color: #323232;
  font-size: 0.8em;
  cursor: pointer;
}

div.calendar-controls .today {
  position: absolute;
  right: 15px;
  line-height: 35px;
  font-size: 0.6em;
  text-transform: uppercase;
}

/* Demo */

div#calendar {
  position: relative;
  border-radius: 5px;
  border: 1px solid #5A5A5A;
  background-color: white;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div#root2 {
  display: table;
  margin: 10px auto;
}

h2,h3,h4,h5 {
  text-align: center;
  color: #404040;
}

h1 {
  text-align: center;
  color: #B10909;
}

a {
  color: #3f51b5  ;
  font-weight: bolder;
  text-decoration: none;
}

a.demoLink {
  text-decoration: underline;
}

div.options {
  border: 1px solid #B9B9B9;
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 30px;
}

div.options select {
  margin-left: 10px;
}

table.calendar td.imbak {
  font-weight: bold;
  color: white;
  background: #3f51b5;
  text-decoration: underline;
}
table.calendar td.weekend {
  font-style: italic;
  font-weight: bold;
}
