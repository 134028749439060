.ant-collapse-header {
  padding: 5px 10px !important;
}

.ant-tabs {
  height: 100%;
}
.ant-tabs-tabpane-active {
  height: 100%;
}
.ant-tabs-content-holder {
  display: flex;
}

.ant-btn {
  border-radius: 5px;
}