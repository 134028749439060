.switchN {
	position: relative;
	height: 35px;
	width: calc(var(--width-slider) * var(--count-slider));
	margin: 10px 4px 10px;
	background: var(--color-main);
	border-radius: 4px;
	box-shadow: inset 0 0 5px rgba(0,0,0, 0.2);
	/* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1); */
}

.switch-labelN {
	position: absolute;
	z-index: 2;
	float: left;
	width: var(--width-slider);
	top: 2px;
	font-size: 1rem;
	font-family: "Arial";
	font-weight: 400;
	color: rgba(0, 0, 0, 0.54);
	transform: translate(0px, calc(100% / 5)) scale(1);
	text-align: center;
	cursor: pointer;
}

.switch-inputN {
	display: none;
}

.switch-selectionN {
	position: absolute;
	top: 2px;
	left: 2px;
	height: calc(100% - 4px);
	width: var(--width-slider);
	border-radius: 4px;
	text-align: center;
	/* color: #3f51b5; */
	background-color: #fff;
	/* background-color: #3f51b5; */
	-webkit-transition: left 0.15s ease-out;
}
