.switch {
	position: relative;
	height: 35px;
	width: 280px;
	margin: 10px 4px 10px;
	background: #f5f5f5;
	border-radius: 4px;
	/* -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1); */
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
}

.switch-label {
	position: absolute;
	z-index: 2;
	/* float: left; */
	width: 140px;
	height: calc(100% - 4px);
	font-size: 1rem;
	font-family: "Arial";
	font-weight: 400;
	color: rgba(0, 0, 0, 0.54);
	transform: translate(0px, calc(100% / 5)) scale(1);
	text-align: center;
	cursor: pointer;
}

.switch-label:active {
	/*   font-weight: bold; */
}

.switch-label-off {
	padding-right: 2px;
	right: 2px;
}

.switch-label-on {
	padding-left: 2px;
	left: 2px;
}

.switch-input {
	display: none;
}

.switch-input:checked+.switch-label {
	font-weight: bold;
	color: white;
	text-shadow: 0 1px rgba(255, 255, 255, 0.25);
	-webkit-transition: 0.15s ease-out;
}

.switch-input:checked+.switch-label-on~.switch-selection {
	left: 2px;
}

.switch-selection {
	position: absolute;
	top: 2px;
	right: 2px;
	/* left: calc(100% - 142px); */
	width: 140px;
	height: calc(100% - 4px);
	border-radius: 4px;
	text-align: center;
	color: white;
	background-color: #3f51b5;
	-webkit-transition: right 0.15s ease-out;
}

.switch-blue .switch-selection {
	/* background-color: #2196f3; */
}
